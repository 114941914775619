// Initialize necessary behaviors
document.addEventListener('turbolinks:load', () => {
  // When customer select changes on user form
  $('#user_customer_id').on('change', function () {
    var customerId = $(this).val();
    var fieldsetUserLevels = $('fieldset.user_levels');
    var clubSelect = $('#user_club_user_attributes_club_id');

    if (customerId) {
      // Update levels
      $.ajax({
        method: 'PUT',
        url: '/users/update_levels',
        data: { customer_id: customerId },
        dataType: 'json',
      }).done(function (result) {
        fieldsetUserLevels.find('div.form-check').remove();

        $.each(result.levels, function (index, level) {
          fieldsetUserLevels.append(
            `<div class="form-check"><input class="form-check-input check_boxes required" required="required" aria-required="true" type="checkbox" value="${level.id}" name="user[level_ids][]" id="user_level_ids_${level.id}"><label class="form-check-label collection_check_boxes" for="user_level_ids_${level.id}">${level.name}</label></div>`
          );
        });
        
        // Reattach event listeners after adding new checkboxes
        attachLevelCheckboxListeners();
      });

      // Update clubs
      $.ajax({
        method: 'GET',
        url: '/clubs',
        data: { customer_id: customerId },
        dataType: 'json',
      }).done(function (clubs) {
        clubSelect.empty();
        clubSelect.append('<option value=""></option>');
        
        $.each(clubs, function (index, club) {
          clubSelect.append(`<option value="${club.id}">${club.name}</option>`);
        });
      });
    } else {
      fieldsetUserLevels.find('div.form-check').remove();
      clubSelect.empty();
      clubSelect.append('<option value=""></option>');
    }
  });

  // Used on filter_form
  $('#user_customer_id_eq').on('change', function() {
    var customerId = $(this).val();

    if (customerId) {
      window.filter_form.showLevels(customerId, false);
    }
  });

  function attachLevelCheckboxListeners() {
    const clubUserFields = document.querySelector('.club-user-fields');
    const levelCheckboxes = document.querySelectorAll('input[name="user[level_ids][]"]');
    
    async function toggleClubUserFields() {
      try {
        const checkedLevels = Array.from(levelCheckboxes)
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value);

        // If no levels are selected, hide the fields
        if (checkedLevels.length === 0) {
          if (clubUserFields) {
            clubUserFields.style.display = 'none';
          }
          return;
        }

        // Check each selected level
        let hasClubUserRole = false;
        for (const levelId of checkedLevels) {
          try {
            const response = await fetch(`/users/check_level_role?level_id=${levelId}`);
            const data = await response.json();
            if (data.has_club_user_role) {
              hasClubUserRole = true;
              break;
            }
          } catch (error) {
            console.error('Error checking level role:', error);
          }
        }

        // Show/hide fields based on result
        if (clubUserFields) {
          clubUserFields.style.display = hasClubUserRole ? 'block' : 'none';
        }
      } catch (error) {
        console.error('Error checking roles:', error);
        if (clubUserFields) {
          clubUserFields.style.display = 'none';
        }
      }
    }

    // Remove existing event listeners and add new ones
    levelCheckboxes.forEach(checkbox => {
      checkbox.removeEventListener('change', toggleClubUserFields);
      checkbox.addEventListener('change', toggleClubUserFields);
    });

    // Run initial check
    toggleClubUserFields();
  }

  // Attach listeners initially
  attachLevelCheckboxListeners();
});
